































import { RecipientInputTs } from './RecipientInputTs';
export default class RecipientInput extends RecipientInputTs {}
