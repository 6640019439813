

























import { MessageInputTs } from './MessageInputTs';
export default class MessageInput extends MessageInputTs {}
