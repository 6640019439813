






import { Component, Vue, Prop } from 'vue-property-decorator';

@Component
export default class ButtonRemove extends Vue {
    @Prop({ default: false }) disabled: boolean;
}
