


















import { ButtonCopyToClipboardTs } from './ButtonCopyToClipboardTs';
export default class ButtonCopyToClipboard extends ButtonCopyToClipboardTs {}
