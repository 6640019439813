

















































import { DashboardInvoicePageTs } from './DashboardInvoicePageTs';
export default class DashboardInvoicePage extends DashboardInvoicePageTs {}
