


























import { MosaicAttachmentInputTs } from './MosaicAttachmentInputTs';
export default class MosaicAttachmentInput extends MosaicAttachmentInputTs {}
