









import LongTextDisplayTs from './LongTextDisplayTs';

export default class LongTextDisplay extends LongTextDisplayTs {}
