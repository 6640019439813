









// external dependencies
import { Component, Vue, Prop } from 'vue-property-decorator';

@Component
export default class ModalFormWrap extends Vue {
    /**
     * Modal title
     * @type {string}
     */
    @Prop({ default: '' }) title: string;

    /**
     * Modal visibility state from parent
     * @type {boolean}
     */
    @Prop({ default: false }) visible: boolean;

    /**
     * Hide footer
     * @type {boolean}
     */
    @Prop({ default: false }) hideFooter: boolean;

    /**
     * Internal visibility state
     * @type {boolean}
     */
    public get show(): boolean {
        return this.visible;
    }

    /**
     * Emits close event
     */
    public set show(val) {
        if (!val) {
            this.$emit('close');
        }
    }
}
