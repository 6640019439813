














































import ModalTransactionUriImportTs from './ModalTransactionUriImportTs';

export default class ModalTransactionUriImport extends ModalTransactionUriImportTs {}
