

























import { ModalTransactionConfirmationTs } from './ModalTransactionConfirmationTs';
export default class ModalTransactionConfirmation extends ModalTransactionConfirmationTs {}
