


































import { AmountInputTs } from './AmountInputTs';
export default class AmountInput extends AmountInputTs {}
