












import { MosaicSelectorTs } from './MosaicSelectorTs';

export default class MosaicSelector extends MosaicSelectorTs {}
