






























import { ContactSelectorTs } from './ContactSelectorTs';

export default class ContactSelector extends ContactSelectorTs {}
