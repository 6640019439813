





















import TransactionUriDisplayTs from './TransactionUriDisplayTs';

export default class TransactionUriDisplay extends TransactionUriDisplayTs {}
